import config from '../config';

const { marketingAppHost, appHost } = config;

export const ageSliderDictionary = {
    1: 8,
    2: 9,
    3: 10,
    4: 11,
    5: 12,
    6: 13,
    7: 14,
    8: 15,
    9: 16,
    10: 17,
    11: 18,
    12: 21,
    13: 25,
    14: 30,
    15: 35,
    16: 40,
    17: 45,
    18: 50,
    19: 55,
    20: 60,
    21: 65,
    22: 70,
};

export const utrpSliderDictionary = {
    1: 'P1',
    2: 'P2',
    3: 'P3',
    4: 'P4',
};

export function getAgeKeyByValue(value) {
    // handle values outside the range
    if (value < 8) {
        return 1;
    }
    if (value > 70) {
        return 22;
    }
    // handle values within the range, fallback to 1 if there is no match
    return Object.keys(ageSliderDictionary).find((key) => ageSliderDictionary[key] === value) || 1;
}

export const UTPRRanges = {
    P1: 1,
    P2: 2,
    P3: 3,
    P4: 4
};

export const DefaultSliderValues = {
    utrRange: [1, 16],
    ageRange: [1, 22],
    utrpRange: [1, 10]
};

export const SortType = {
    ASC: 'asc',
    DESC: 'desc',
};

export const ResponseStatuses = {
    OK: 200,
    CREATED: 201,
    NO_CONTENT: 204,
    NOT_FOUND: 404,
};

export const LeagueTabs = {
    CAPTAINS: 'captains',
    CONFERENCES: 'conferences',
    DETAILS: 'details',
    EDIT_CAPTAINS: 'edit captains',
    EDIT_SETTINGS: 'edit settings',
    ORGANIZERS: 'organizers',
    OVERVIEW: 'overview',
    PLAYERS: 'players',
    ROSTER: 'roster',
    SCHEDULE: 'schedule',
    SESSIONS: 'sessions',
    TEAMS: 'teams',
    DRAW: 'draw',
    LINES: 'lines',
    LOCATION: 'location',
    AWAY_TEAM: 'away team',
    HOME_TEAM: 'home team'
};

export const ManageSessionTabs = {
    TEAMS: 'teams',
    SCHEDULE: 'schedule',
    DRAW: 'draw',
    PLAYERS: 'players',
    DETAILS: 'details'
};

export const SessionTabs = {
    OVERVIEW: 'overview',
    SCHEDULE: 'schedule',
    DRAW: 'draw',
    PLAYERS: 'players',
    STANDINGS: 'team standings',
};

export const BreadcrumbContexts = {
    LEAGUE: 'league',
    CONFERENCE: 'conference',
    SESSION: 'session',
    TEAM: 'team',
};

export const LeagueRoles = {
    LEAGUE_ADMIN: 'League Admin',
    CONFERENCE_ORGANIZER: 'Conference Organizer',
    TEAM_CAPTAIN: 'Team Captain',
};

export const DistanceSliderMarks = [
    { value: 10, label: '10mi' },
    { value: 25, label: '25mi' },
    { value: 50, label: '50mi' },
    { value: 75, label: '75mi' },
    { value: 100, label: '100mi' },
    { value: 125, label: '125mi' },
];

export const dateFormat = 'MMM Do YYYY';
export const dateTimeFormat = 'MMM Do YYYY - h:mm A';
export const defaultGridSpacing = 3;
export const formPaperStyles = { px: 4, py: 2, my: 5 };
export const SESSION_PAGE = 'SESSION_PAGE';
export const TEAM_PAGE = 'TEAM_PAGE';
export const homeTeamIndicator = '(H)';

export const ScorePositionKeys = {
    POSITION_ONE_SCORE: 'position1Score',
    POSITION_ONE_TB_SCORE: 'position1Tiebreaker',
    POSITION_TWO_SCORE: 'position2Score',
    POSITION_TWO_TB_SCORE: 'position2Tiebreaker'
};

export const managingTennisHeadCells = [
    { id: 'name', label: 'Name' },
    { id: 'singlesUtr', label: 'UTR-T (S)' },
    { id: 'doublesUtr', label: 'UTR-T (D)' },
    { id: 'matchesPlayed', label: 'Matches' },
    { id: 'actions' },
];

export const tennisHeadCells = [
    { id: 'name', label: 'Name' },
    { id: 'singlesUtr', label: 'UTR-T (S)' },
    { id: 'doublesUtr', label: 'UTR-T (D)' },
    { id: 'record', label: 'Record' },
    { id: 'actions' },
];

export const teamProfileTennisHeadCells = [
    { id: 'singlesUtr', label: 'UTR-T (S)' },
    { id: 'doublesUtr', label: 'UTR-T (D)' },
    { id: 'singlesRecord', label: 'Singles Record' },
    { id: 'doublesRecord', label: 'Doubles Record' },
    { id: 'actions' },
];

export const managingPickleballHeadCells = [
    { id: 'name', label: 'Name' },
    { id: 'utrp-numeric-single', label: 'UTR-P' },
    { id: 'utrp-numeric-double', label: 'UTR-P' },
    { id: 'matchesPlayed', label: 'Matches' },
    { id: 'actions' },
];

export const pickleballHeadCells = [
    { id: 'name', label: 'Name' },
    { id: 'utrp-numeric-single', label: 'UTR-P' },
    { id: 'utrp-numeric-double', label: 'UTR-P' },
    { id: 'record', label: 'Record' },
    { id: 'actions' },
];

export const teamProfilePickleballHeadCells = [
    { id: 'utrp-numeric-single', label: 'UTR-P' },
    { id: 'utrp-numeric-double', label: 'UTR-P' },
    { id: 'singlesRecord', label: 'Singles Record' },
    { id: 'doublesRecord', label: 'Doubles Record' },
    { id: 'actions' },
];

export const tennisTeamMatchHeadCells = [
    { id: 'name', label: 'Rostered Players' },
    { id: 'playerAvailabilityStatusTypeId' },
    { id: 'singlesUtr', label: 'UTR-T (S)' },
    { id: 'doublesUtr', label: 'UTR-T (D)' },
    { id: 'singlesRecord', label: 'Singles Record' },
    { id: 'doublesRecord', label: 'Doubles Record' },
];

export const pickleballTeamMatchHeadCells = [
    { id: 'name', label: 'Rostered Players' },
    { id: 'playerAvailabilityStatusTypeId' },
    { id: 'utrp', label: 'UTRP' },
    { id: 'singlesRecord', label: 'Singles Record' },
    { id: 'doublesRecord', label: 'Doubles Record' },
];

export const tennisSubstituteTeamMatchHeadCells = [
    { id: 'name', label: 'Substitute Players' },
    { id: 'playerAvailabilityStatusTypeId' },
    { id: 'singlesUtr', label: 'UTR-T (S)' },
    { id: 'doublesUtr', label: 'UTR-T (D)' },
    { id: 'singlesRecord', label: 'Singles Record' },
    { id: 'doublesRecord', label: 'Doubles Record' },
];

export const pickleballSubstituteTeamMatchHeadCells = [
    { id: 'name', label: 'Substitute Players' },
    { id: 'playerAvailabilityStatusTypeId' },
    { id: 'utrp', label: 'UTRP' },
    { id: 'singlesRecord', label: 'Singles Record' },
    { id: 'doublesRecord', label: 'Doubles Record' },
];

export const sessionStandingsHeadCells = [
    { id: 'rank', label: 'Rank' },
    { id: 'teamName', label: 'Name' },
    { id: 'teamPowerIndexSingles', label: 'TPI S' },
    { id: 'teamPowerIndexDoubles', label: 'TPI D' },
    { id: 'teamMatchWins', label: 'Wins' },
    { id: 'teamMatchLosses', label: 'Losses' },
    { id: 'playerMatchPoints', label: 'Points' },
];

export const GridSizes = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
    SEVEN: 7,
    EIGHT: 8,
    NINE: 9,
    TEN: 10,
    ELEVEN: 11,
    TWELVE: 12
};

export const TypographySizes = {
    LARGE: 'large',
    LARGE_BOLD: 'large-bold',
    LARGE_BOOK: 'large-book',
    LARGE_LIGHT: 'large-light',
    LARGE_MEDIUM: 'large-medium',
    MEDIUM_BOOK: 'medium-book',
    MEDIUM_MEDIUM: 'medium-medium',
    MEDIUM_SEMIBOLD: 'medium-semibold',
    SMALL_BOOK: 'small-book',
    SMALL_MEDIUM: 'small-medium',
    SMALL_MEDIUM_CAP: 'small-medium-cap',
    X_SMALL_BOOK: 'x-small-book',
    X_SMALL_BOOK_CAP: 'x-small-book-cap',
    X_SMALL_MEDIUM: 'x-small-medium',
    X_SMALL_MEDIUM_CAP: 'x-small-medium-cap',
    XX_SMALL_BOOK: 'xx-small-book',
    XX_SMALL_MEDIUM_CAP: 'xx-small-medium-cap',
};

export const SharedUICategories = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    DISPLAY: 'display',
    PRIMARY_EXPANDED: 'primary-expanded',
    SECONDARY_FLIPPED: 'secondary-flipped',
    LINK: 'link'
};

export const LineupStatusType = {
    DRAFT: 1,
    LOCKED: 2,
    PUBLISHED: 3
};

export const LineupButtonLabels = {
    PUBLISH: 'Publish',
    UNPUBLISH: 'Unpublish',
    LOCK: 'Lock',
    UNLOCK: 'Unlock'
};

export const LineupButtonTooltipText = {
    PUBLISH_TOOLTIP: 'Publishing will make your lineup visible to players outside your team',
    UNPUBLISH_TOOLTIP: 'Unpublishing will revert the lineup to "Locked," so only your team can see it',
    LOCK_TOOLTIP: 'Locking will allow your team members to see the lineup',
    UNLOCK_TOOLTIP: 'Unlocking will limit lineup visibility to team captains only'
};

export const FooterExploreItems = [
    { href: `${appHost}/cities`, label: 'Cities' },
    { href: `${appHost}/states`, label: 'States' },
    { href: `${appHost}/countries`, label: 'Countries' },
];

export const FooterProviderItems = [
    { href: `${marketingAppHost}/pages/clubs`, label: 'Clubs' },
    { href: `${marketingAppHost}/pages/academies`, label: 'Academies' },
    { href: `${marketingAppHost}/pages/high-school`, label: 'High Schools' },
    { href: `${marketingAppHost}/pages/college`, label: 'Colleges' },
    { href: `${marketingAppHost}/pages/get-in-touch`, label: 'Get in Touch' },
];

export const FooterMoreItems = [
    { href: `${marketingAppHost}/blogs/news`, label: 'News' },
    { href: `${marketingAppHost}/blogs/press`, label: 'Press' },
    { href: `${marketingAppHost}/collections/all`, label: 'Shop' },
    { href: 'https://apply.workable.com/universal-tennis/', label: 'Careers' },
    { href: `${marketingAppHost}/pages/about-us`, label: 'About Us' },
    { href: `${marketingAppHost}/pages/foundation`, label: 'Foundation' },
    { href: 'https://insights.utrsports.net/', label: 'Pro Insights' },
];

export const FooterSupportItems = [
    { href: 'https://support.universaltennis.com/support/home', label: 'Help Center' },
    { href: `${marketingAppHost}/policies/privacy-policy`, label: 'Privacy Policy' },
    { href: `${marketingAppHost}/policies/terms-of-service`, label: 'Terms & Conditions' },
    { href: `${marketingAppHost}/pages/guidelines`, label: 'Guidelines' },
    { href: `${marketingAppHost}/pages/verified`, label: 'Verified Events' },
    { href: `${marketingAppHost}/pages/how-utr-works`, label: 'About UTR Rating' },
    { href: `${marketingAppHost}/pages/how-utr-p-works`, label: 'About UTR-P Rating' },
];

export const FooterSocialMedia = [
    { id: 1, label: 'X', href: 'https://x.com/UTR_Sports_' },
    { id: 2, label: 'Facebook', href: 'https://www.facebook.com/UTRSports' },
    { id: 3, label: 'Instagram', href: 'https://www.instagram.com/utr_sports/' },
    { id: 4, label: 'TikTok', href: 'https://www.tiktok.com/@utr_sports' },
    { id: 5, label: 'YouTube', href: 'https://www.youtube.com/c/myutr' },
];

export const RegistrationStepIds = {
    TEAM_SELECTION: 1,
    PASSWORD_CONFIRMATION: 2,
    EVENT_QUESTIONS: 3,
    CONFIRM_DETAILS: 4
};
